import React, { useContext, useState, useEffect, createContext } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useSearchParams } from 'react-router-dom';

import { useBaseConfig } from '../useBaseConfig';
import { authFetch as authFetchUtil } from './useConfig.utils';
import { iframe } from '../../utils';

import type { AppConfigBase, OktaAuthServiceBase } from '../useBaseConfig/useConfig.types';
import type { EnvironmentType, PropertyType } from '../../types';

const ConfigContext = createContext({});

type BaseConfigType = {
  app: AppConfigBase;
  auth?: OktaAuthServiceBase;
};

interface ConfigType extends BaseConfigType {}

type SessionType = {
  appUrl: string;
  env: EnvironmentType;
  property: PropertyType;
};

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  const { currentProperty, oktaAuth } = useAuth();
  const { baseConfig } = useBaseConfig();
  // @ts-ignore
  const [config, setConfig] = useState<ConfigType>(baseConfig);
  const [session, setSession] = useState<SessionType>({
    appUrl: config.app.appUrl,
    env: config.app.env as EnvironmentType,
    property: window.location.pathname.split('/')[1] as PropertyType,
  });

  const [searchParams] = useSearchParams();
  const ref = searchParams.get('ref') || '';
  const source = searchParams.get('source') || '';

  useEffect(() => {
    if (![currentProperty, window.location.pathname.split('/')[1]].includes(session.property)) {
      const updateProperty = currentProperty || window.location.pathname.split('/')[1];
      setSession({
        ...session,
        ...{ property: updateProperty as PropertyType },
      });
    }
  }, [currentProperty]);

  useEffect(() => {
    iframe.initializeSessionStorage(ref, source);
  }, [ref, source]);

  useEffect(() => {
    setConfig({ ...config, ...baseConfig });
  }, [baseConfig]);

  const authFetch = async (resource: string, options?: RequestInit) => {
    // @ts-ignore - getTokens is a valid method
    const newTokens = await oktaAuth.tokenManager.getTokens();
    const fetchOptions = {
      resource,
      options,
      appUrl: config.app.appUrl || '',
      property: session.property || currentProperty,
      accessToken: newTokens.accessToken?.accessToken,
    };
    return authFetchUtil(fetchOptions);
  };

  return (
    <ConfigContext.Provider value={{ config: config as ConfigType, session: session as SessionType, authFetch }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context as {
    config: BaseConfigType;
    session: SessionType;
    authFetch: (resource: string, options?: RequestInit) => Promise<any>;
  };
}

export default useConfig;
