/* eslint-disable */ 
 const MD5_HASH = {
  e5ea: 'barrons',
  '2a15': 'barrons',
  '9e03': 'barrons',
  '7e3e': 'wsjcommerce',
  e5a0: 'wsjcommerce',
  e9e6: 'wsjcommerce',
  '6fe1': 'wsjopinion',
  '4d82': 'wsjopinion',
  '8bad': 'wsjopinion',
  b255: 'wsjopinion',
  4810: 'fnlondon',
  '34ac': 'fnlondon',
  d5e3: 'fnlondon',
  3454: 'mansionglobal',
  e677: 'mansionglobal',
  '94ef': 'mansionglobal',
  '7d28': 'mansionglobal',
  '8bee': 'mansionglobal',
  '46f6': 'mansionglobal',
  d32d: 'marketwatch',
  '61bd': 'marketwatch',
  '96ef': 'marketwatch',
  cd7b: 'pen',
  cf36: 'pen',
  b0dc: 'pen',
  1032: 'wsj',
  '78a8': 'wsj',
  '7cbd': 'wsj',
  c8ec: 'ydj',
  '37ef': 'ydj',
  acf5: 'ydj'
};
const COMMON_CONFIG = {
  DEFAULT_WIDTH: 1280,
  DEFAULT_THUMB_SIZE_WIDTH: 320,
  DEFAULT_THUMB_SIZE_HEIGHT: 213,
  DEFAULT_MIN_THUMB_DPR: null,
  DEFAULT_QUALITY: 'auto:eco',
  DEFAULT_EXT: 'auto',
  DEFAULT_GRAVITY: 'auto',
  DEFAULT_CROP: 'fill',
  DEFAULT_THUMB_CROP: 'thumb'
};
const COMMON_NAMED_SIZES = {
  social: {
    width: 1280,
    height: 640
  },
  preview: {
    height: 530,
    dpr: 2
  },
  previewsquarethumb: {
    width: 250,
    height: 250,
    dpr: 2
  }
};
export const DEFAULT_PROPERTY_CONFIG = {
  wsj: {
    DEFAULT_MIN_THUMB_DPR: 1.5,
    SIZES: {
      landscape: {
        width: 900,
        height: 600
      },
      widethmb: {
        width: 110,
        height: 73
      },
      portrait: {
        width: 600,
        height: 900
      },
      squarethmb: {
        width: 76,
        height: 76
      },
      panorama: {
        width: 300,
        height: 150
      },
      top: {
        width: 700,
        height: 350
      },
      c: {
        width: 167,
        height: 94
      },
      a: {
        width: 76,
        height: 76
      },
      d: {
        width: 262,
        height: 174
      },
      m: {
        width: 1280,
        height: 853
      },
      z120: {
        width: 120,
        height: 80
      },
      e: {
        width: 359,
        height: 239
      },
      g: {
        width: 553,
        height: 369
      },
      er: {
        width: 602,
        height: 402
      },
      gr: {
        width: 1242,
        height: 810
      },
      am: {
        width: 220,
        height: 220
      },
      f: {
        width: 571,
        height: 226
      },
      fr: {
        width: 632,
        height: 810
      },
      br: {
        width: 282,
        height: 188
      },
      wn: {
        width: 640,
        height: 360
      },
      p: {
        width: 749,
        height: 499
      },
      j: {
        width: 959,
        height: 639
      },
      h: {
        width: 659,
        height: 439
      },
      '16h': {
        width: 1274,
        height: 849
      },
      dv: {
        width: 262,
        height: 394
      },
      '12s': {
        width: 954,
        height: 954
      },
      hd: {
        width: 1024,
        height: 640
      },
      ea: {
        width: 458,
        height: 315
      },
      jv: {
        width: 426,
        height: 639
      },
      gv: {
        width: 359,
        height: 550
      },
      '8s': {
        width: 634,
        height: 634
      },
      gs: {
        width: 553,
        height: 553
      },
      '4s': {
        width: 300,
        height: 300
      },
      ba: {
        width: 113,
        height: 113
      },
      '8v': {
        width: 634,
        height: 951
      },
      av: {
        width: 78,
        height: 117
      },
      cv: {
        width: 165,
        height: 249
      },
      bv: {
        width: 124,
        height: 213
      },
      bc: {
        width: 60,
        height: 60
      },
      ev: {
        width: 359,
        height: 539
      },
      hv: {
        width: 293,
        height: 439
      },
      '12h': {
        width: 954,
        height: 636
      },
      '8h': {
        width: 634,
        height: 423
      },
      '16p': {
        width: 1274,
        height: 450
      },
      mp: {
        width: 799,
        height: 534
      },
      dw: {
        width: 232,
        height: 200
      },
      oz: {
        width: 570,
        height: 612
      },
      ks: {
        width: 800,
        height: 800
      },
      ma: {
        width: 86,
        height: 86
      },
      mb: {
        width: 140,
        height: 93
      },
      im: {
        width: 2000,
        height: 1026
      },
      small: {
        width: 76,
        height: 76
      },
      medium: {
        width: 553,
        height: 369
      },
      large: {
        width: 1280,
        height: 853
      },
      '8sr': {
        width: 1278,
        height: 1278
      },
      square: {
        width: 1278,
        height: 1278
      },
      nlhorizontal: {
        width: 540,
        height: 360
      },
      nlarticle: {
        width: 540,
        height: 405
      },
      nlpano: {
        width: 540,
        height: 305
      },
      nlsquare: {
        width: 540,
        height: 540
      },
      lv: {
        width: 1242,
        height: 1590
      }
    },
    LOCATIONS: ['wsj', 'wires']
  },
  wsjopinion: {
    DEFAULT_MIN_THUMB_DPR: 1.5,
    SIZES: {
      landscape: {
        width: 900,
        height: 600
      },
      widethmb: {
        width: 110,
        height: 73
      },
      portrait: {
        width: 600,
        height: 900
      },
      squarethmb: {
        width: 76,
        height: 76
      },
      panorama: {
        width: 300,
        height: 150
      },
      top: {
        width: 700,
        height: 350
      },
      c: {
        width: 167,
        height: 94
      },
      a: {
        width: 76,
        height: 76
      },
      d: {
        width: 262,
        height: 174
      },
      m: {
        width: 1280,
        height: 853
      },
      z120: {
        width: 120,
        height: 80
      },
      e: {
        width: 359,
        height: 239
      },
      g: {
        width: 553,
        height: 369
      },
      er: {
        width: 602,
        height: 402
      },
      gr: {
        width: 1242,
        height: 810
      },
      am: {
        width: 220,
        height: 220
      },
      f: {
        width: 571,
        height: 226
      },
      fr: {
        width: 632,
        height: 810
      },
      br: {
        width: 282,
        height: 188
      },
      wn: {
        width: 640,
        height: 360
      },
      p: {
        width: 749,
        height: 499
      },
      j: {
        width: 959,
        height: 639
      },
      h: {
        width: 659,
        height: 439
      },
      '16h': {
        width: 1274,
        height: 849
      },
      dv: {
        width: 262,
        height: 394
      },
      '12s': {
        width: 954,
        height: 954
      },
      hd: {
        width: 1024,
        height: 640
      },
      ea: {
        width: 458,
        height: 315
      },
      jv: {
        width: 426,
        height: 639
      },
      gv: {
        width: 359,
        height: 550
      },
      '8s': {
        width: 634,
        height: 634
      },
      gs: {
        width: 553,
        height: 553
      },
      '4s': {
        width: 300,
        height: 300
      },
      ba: {
        width: 113,
        height: 113
      },
      '8v': {
        width: 634,
        height: 951
      },
      av: {
        width: 78,
        height: 117
      },
      cv: {
        width: 165,
        height: 249
      },
      bv: {
        width: 124,
        height: 213
      },
      bc: {
        width: 60,
        height: 60
      },
      ev: {
        width: 359,
        height: 539
      },
      hv: {
        width: 293,
        height: 439
      },
      '12h': {
        width: 954,
        height: 636
      },
      '8h': {
        width: 634,
        height: 423
      },
      '16p': {
        width: 1274,
        height: 450
      },
      mp: {
        width: 799,
        height: 534
      },
      dw: {
        width: 232,
        height: 200
      },
      oz: {
        width: 570,
        height: 612
      },
      ks: {
        width: 800,
        height: 800
      },
      ma: {
        width: 86,
        height: 86
      },
      mb: {
        width: 140,
        height: 93
      },
      im: {
        width: 2000,
        height: 1026
      },
      small: {
        width: 76,
        height: 76
      },
      medium: {
        width: 553,
        height: 369
      },
      large: {
        width: 1280,
        height: 853
      },
      '8sr': {
        width: 1278,
        height: 1278
      },
      square: {
        width: 1278,
        height: 1278
      },
      nlhorizontal: {
        width: 540,
        height: 360
      },
      nlarticle: {
        width: 540,
        height: 405
      },
      nlpano: {
        width: 540,
        height: 305
      },
      nlsquare: {
        width: 540,
        height: 540
      },
      lv: {
        width: 1242,
        height: 1590
      }
    },
    LOCATIONS: ['wsjopinion', 'wires']
  },
  barrons: {
    SIZES: {
      widethmb: {
        width: 114,
        height: 76
      },
      vertical: {
        width: 600,
        height: 900
      },
      panorama: {
        width: 300,
        height: 150
      },
      or: {
        width: 900,
        height: 600
      },
      horizontal: {
        width: 900,
        height: 600
      },
      square: {
        width: 76,
        height: 76
      },
      small: {
        width: 76,
        height: 76
      },
      medium: {
        width: 553,
        height: 369
      },
      large: {
        width: 1280,
        height: 853
      },
      im: {
        width: 2000,
        height: 1026
      },
      lgsquareportrait: {
        width: 600,
        height: 600
      }
    },
    LOCATIONS: ['barrons']
  },
  mansionglobal: {
    SIZES: {
      horizontal: {
        width: 1280,
        height: 720
      },
      vertical: {
        width: 600,
        height: 900
      }
    },
    LOCATIONS: ['mansionglobal']
  },
  marketwatch: {
    SIZES: {
      vertical: {
        width: 742,
        height: 1320
      },
      square: {
        width: 86,
        height: 86
      },
      widethmb: {
        width: 114,
        height: 76
      },
      widepano: {
        width: 1800,
        height: 400
      },
      horizontal: {
        width: 1320,
        height: 742
      }
    },
    LOCATIONS: ['marketwatch', 'wires/Getty', 'wires/AFP']
  },
  ydj: {
    SIZES: {},
    LOCATIONS: ['ydj', 'newswires']
  },
  fnlondon: {
    SIZES: {
      square: {
        width: 86,
        height: 86
      },
      horizontal: {
        width: 1280,
        height: 853
      },
      wide: {
        width: 1280,
        height: 720
      },
      vertical: {
        width: 853,
        height: 1280
      }
    },
    LOCATIONS: ['fnlondon']
  },
  pen: {
    SIZES: {
      square: {
        width: 86,
        height: 86
      },
      horizontal: {
        width: 1280,
        height: 853
      },
      wide: {
        width: 1280,
        height: 720
      },
      vertical: {
        width: 853,
        height: 1280
      }
    },
    LOCATIONS: ['pen']
  },
  wsjcommerce: {
    SIZES: {
      squarethmb: {
        width: 76,
        height: 76
      },
      widethmb: {
        width: 110,
        height: 73
      },
      landscape: {
        width: 940,
        height: 529
      },
      square: {
        width: 1278,
        height: 1278
      },
      portrait: {
        width: 600,
        height: 900
      },
      panorama: {
        width: 300,
        height: 150
      }
    },
    LOCATIONS: ['wsjcommerce']
  }
};
function createRound(methodName) {
  const func = Math[methodName];
  return (number, precision) => {
    precision = precision == null ? 0 : precision >= 0 ? Math.min(precision, 292) : Math.max(precision, -292);
    if (precision) {
      let pair = `${number}e`.split('e');
      const value = func(`${pair[0]}e${+pair[1] + precision}`);
      pair = `${value}e`.split('e');
      return +`${pair[0]}e${+pair[1] - precision}`;
    }
    return func(number);
  };
}
const round = createRound('round');
const closestSizedCrop = (crops, options) => {
  return crops.reduce((acc, sizedImage) => {
    if (sizedImage.outputWidth === options.outputWidth && sizedImage.outputHeight === options.outputHeight) {
      return sizedImage;
    }
    if (options.outputWidth && options.outputHeight || options.outputWidth && !options.outputHeight) {
      if (sizedImage.outputWidth === options.outputWidth && acc.outputWidth === options.outputWidth || sizedImage.outputWidth === acc.outputWidth) {
        if (Math.abs(sizedImage.outputHeight - options.outputHeight) < Math.abs(acc.outputHeight - options.outputHeight)) {
          return sizedImage;
        } else {
          return acc;
        }
      } else if (sizedImage.outputWidth === options.outputWidth) {
        return sizedImage;
      }
      if (Math.abs(sizedImage.outputWidth - options.outputWidth) < Math.abs(acc.outputWidth - options.outputWidth)) {
        return sizedImage;
      }
      return acc;
    } else if (options.outputHeight && !options.outputWidth) {
      if (sizedImage.outputHeight === options.outputHeight && acc.outputHeight === options.outputHeight || sizedImage.outputHeight === acc.outputHeight) {
        if (Math.abs(sizedImage.outputWidth) > Math.abs(acc.outputWidth)) {
          return sizedImage;
        } else {
          return acc;
        }
      } else if (sizedImage.outputHeight === options.outputHeight) {
        return sizedImage;
      }
      if (Math.abs(sizedImage.outputHeight - options.outputHeight) < Math.abs(acc.outputHeight - options.outputHeight)) {
        return sizedImage;
      }
      return acc;
    }
  });
};
function chooseCrop(options) {
  const seedCrops = options.crops || options.seedCrops;
  seedCrops.forEach(crop => {
    crop.size = crop.outputWidth / crop.outputHeight;
  });
  const sizeSort = seedCrops.sort((a, b) => {
    if (a.outputHeight * a.outputWidth > b.outputHeight * b.outputWidth) {
      return 1;
    }
    return -1;
  });
  if (!options.outputHeight && !options.outputWidth && !options.aspectRatio) {
    return sizeSort[0];
  }
  if (options.aspectRatio) {
    if (!options.outputWidth) {
      options.outputWidth = Math.round(options.outputHeight * options.aspectRatio);
    }
    if (!options.outputHeight) {
      options.outputHeight = Math.round(options.outputWidth / options.aspectRatio);
    }
  }
  const outputScale = parseFloat(1);
  if (outputScale && (options.outputHeight || options.outputWidth)) {
    const scaleOptions = Object.assign({}, {
      ...options
    });
    if (options.outputHeight) {
      scaleOptions.outputHeight = options.outputHeight * outputScale;
    }
    if (options.outputWidth) {
      scaleOptions.outputWidth = options.outputWidth * outputScale;
    }
    const crop = closestSizedCrop(seedCrops, scaleOptions);
    if (scaleOptions.outputWidth && scaleOptions.outputHeight) {
      if (crop.outputHeight === scaleOptions.outputHeight && scaleOptions.outputWidth === crop.outputWidth) {
        return crop;
      }
    } else if (scaleOptions.outputWidth) {
      if (scaleOptions.outputWidth === crop.outputWidth) {
        return crop;
      }
    } else {
      if (crop.outputHeight === scaleOptions.outputHeight) {
        return crop;
      }
    }
  }
  delete options.outputScale;
  const crops = seedCrops.filter(sizedImage => {
    if (options.outputWidth && options.outputHeight) {
      return sizedImage.outputWidth <= options.outputWidth && sizedImage.outputHeight <= options.outputHeight;
    } else if (options.outputWidth) {
      return sizedImage.outputWidth <= options.outputWidth;
    } else if (options.outputHeight) {
      return sizedImage.outputHeight <= options.outputHeight;
    }
    return false;
  });
  if (!crops.length) {
    return sizeSort[0];
  }
  ;
  const crop = closestSizedCrop(crops, options);
  return crop;
}
const closestInArr = (arr, closestTo, threshold) => {
  if (arr.indexOf(closestTo) >= 0) {
    return closestTo;
  }
  var newArr = arr.slice();
  newArr.push(closestTo);
  newArr.sort(function (a, b) {
    return a - b;
  });
  var updatedClosestToIndx = newArr.indexOf(closestTo);
  if (closestTo <= (threshold || 300)) {
    updatedClosestToIndx -= 1;
  }
  if (updatedClosestToIndx < 0) {
    updatedClosestToIndx = 0;
  }
  if (updatedClosestToIndx >= newArr.length - 1) {
    updatedClosestToIndx = newArr.length - 2;
  }
  newArr.splice(newArr.indexOf(closestTo), 1);
  var result = newArr[updatedClosestToIndx];
  return result;
};
function getClosestSize(crop, widths, heights, outputHeight, outputWidth, availableCrops, imageInnerBounds, imageOuterBounds, imageHeight, imageWidth, threshold) {
  var closestWidth = closestInArr(widths, outputWidth, threshold.width);
  var closestHeight = closestInArr(heights, outputHeight, threshold.height);
  var closestWidthIdx = widths && widths.indexOf(closestWidth);
  var closestHeightIdx = heights && heights.indexOf(closestHeight);
  if (closestWidthIdx < 0) {
    closestWidthIdx = 0;
  }
  if (closestHeightIdx < 0) {
    closestHeightIdx = 0;
  }
  if (widths.length < closestWidthIdx) {
    closestWidthIdx = widths.length;
  }
  if (heights.length < closestHeightIdx) {
    closestHeightIdx = heights.length;
  }
  crop.width = availableCrops[closestWidthIdx].width / 100 * imageWidth;
  crop.height = availableCrops[closestHeightIdx].height / 100 * imageHeight;
  crop.x = availableCrops[closestWidthIdx].x / 100 * imageWidth;
  crop.y = availableCrops[closestHeightIdx].y / 100 * imageHeight;
  crop.aspectRatio = availableCrops[closestWidthIdx].width * imageWidth / (availableCrops[closestHeightIdx].height * imageHeight);
  if (outputWidth / outputHeight !== crop.aspectRatio) {
    var newHeight = outputHeight / outputWidth * crop.width;
    var newWidth = outputWidth / outputHeight * crop.height;
    var heightDiff = crop.height - newHeight;
    var widthDiff = crop.width - newWidth;
    var newSize = {
      fromWidth: {
        width: crop.width,
        height: newHeight
      },
      fromHeight: {
        width: newWidth,
        height: crop.height
      }
    };
    crop.extra = newSize;
    if (newHeight + crop.y <= imageHeight) {
      crop.y = crop.y + heightDiff / 2;
      newWidth = crop.width;
      crop.height = newHeight;
    }
    if (newWidth + crop.x <= imageWidth) {
      crop.x = crop.x + widthDiff / 2;
      if (newWidth < imageInnerBounds.width) {
        var updatedHeight = outputHeight / outputWidth * imageInnerBounds.width;
        if (updatedHeight <= imageOuterBounds.height) {
          newWidth = imageInnerBounds.width;
        } else {
          updatedHeight = imageOuterBounds.height;
          newWidth = outputWidth / outputHeight * updatedHeight;
        }
        crop.height = updatedHeight;
      }
      crop.width = newWidth;
    }
    if (crop.y > imageInnerBounds.y || crop.y + crop.height < imageInnerBounds.yh || crop.x > imageInnerBounds.x || crop.x + crop.width < imageInnerBounds.xw) {
      if (crop.y > imageInnerBounds.y || crop.y + crop.height < imageInnerBounds.yh) {
        if (crop.y > imageInnerBounds.y && crop.y + crop.height < imageInnerBounds.yh) {} else if (crop.y > imageInnerBounds.y) {
          crop.y = imageInnerBounds.y;
          crop.y = imageInnerBounds.y + (imageInnerBounds.height - crop.height) / 2;
        } else if (crop.y + crop.height < imageInnerBounds.yh) {
          crop.y = imageInnerBounds.y;
          if (crop.height < imageInnerBounds.height) {
            crop.y = crop.y + (imageInnerBounds.height - crop.height) / 2;
          } else {
            crop.y = crop.y - (crop.height - imageInnerBounds.height) / 2;
          }
          if (crop.y + crop.height > imageHeight) {
            crop.y = crop.y - (crop.y + crop.height - imageHeight);
          }
        }
      }
      if (crop.x > imageInnerBounds.x || crop.x + crop.width < imageInnerBounds.xw) {
        if (crop.x > imageInnerBounds.x && crop.x + crop.width < imageInnerBounds.xw) {} else if (crop.x > imageInnerBounds.x) {
          crop.x = imageInnerBounds.x;
          crop.x = imageInnerBounds.x + (imageInnerBounds.width - crop.width) / 2;
        } else if (crop.x + crop.width < imageInnerBounds.xw) {
          crop.x = imageInnerBounds.x;
          if (crop.width < imageInnerBounds.width) {
            crop.x = crop.x + (imageInnerBounds.width - crop.width) / 2;
          } else {
            crop.x = crop.x - (crop.width - imageInnerBounds.width) / 2;
          }
          if (crop.x + crop.width > imageWidth) {
            crop.x = crop.x - (crop.x + crop.width - imageWidth);
          }
        }
      }
    } else {
      if (crop.height >= crop.width && crop.width >= imageInnerBounds.width && crop.width > threshold.width) {
        for (var i = 0; i < availableCrops.length; i++) {
          var widthOpts = [];
          var xOpts = [];
          if (availableCrops[i].outputHeight > availableCrops[i].outputWidth) {
            widthOpts.push(availableCrops[i].width / 100 * imageWidth);
            xOpts.push(availableCrops[i].x);
          }
          if (widthOpts.length === 1) {
            crop.x = xOpts[0] / 100 * imageWidth;
          }
        }
      }
    }
    if (crop.width < imageInnerBounds.width) {
      var newHeight = outputHeight / outputWidth * imageInnerBounds.width;
      if (newHeight <= imageOuterBounds.height) {
        crop.width = imageInnerBounds.width;
        crop.height = newHeight;
        crop.y = imageInnerBounds.y + (imageInnerBounds.height - crop.height) / 2;
        crop.x = imageInnerBounds.x;
      }
    }
    if (crop.height < imageInnerBounds.height) {
      var newWidth = outputWidth / outputHeight * imageInnerBounds.height;
      if (newWidth <= imageOuterBounds.width) {
        crop.width = newWidth;
        crop.height = imageInnerBounds.height;
        crop.y = imageInnerBounds.y;
        crop.x = imageInnerBounds.x + (imageInnerBounds.width - crop.width) / 2;
      }
    }
    if (crop.x + crop.width > imageOuterBounds.x + imageOuterBounds.width) {
      crop.x = crop.x - (crop.x + crop.width - (imageOuterBounds.x + imageOuterBounds.width));
    }
    if (crop.y + crop.height > imageOuterBounds.y + imageOuterBounds.height) {
      crop.y = crop.y - (crop.y + crop.height - (imageOuterBounds.y + imageOuterBounds.height));
    }
    if (crop.x < 0) {
      crop.x = 0;
    }
    if (crop.y < 0) {
      crop.y = 0;
    }
  }
  return crop;
}
function smartCropAlgorithm(options) {
  var debug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var crops = options.crops || options.seedCrops;
  if (!crops || crops.length < 1) {
    crops = {};
  }
  var imageWidth = parseFloat(options.imageWidth);
  var imageHeight = parseFloat(options.imageHeight);
  var outputWidth = parseFloat(options.outputWidth);
  var outputHeight = parseFloat(options.outputHeight);
  var outputScale = parseFloat(options.outputScale);
  var aspectRatio = parseFloat(options.aspectRatio);
  outputScale = options.outputScale <= 0 ? 1 : options.outputScale;
  var threshold = {};
  var thresholdDefault = 199;
  var thresholdDefaultSquare = 299;
  if (typeof options.threshold === "object") {
    threshold.width = options.threshold.width || thresholdDefault;
    threshold.height = options.threshold.height || thresholdDefault;
    threshold.square = options.threshold.square || thresholdDefaultSquare;
  } else {
    threshold.width = options.threshold || thresholdDefault;
    threshold.height = options.threshold || thresholdDefault;
    threshold.square = options.threshold || thresholdDefaultSquare;
  }
  if (outputWidth && !outputHeight) {
    aspectRatio = aspectRatio || imageWidth / imageHeight;
    outputHeight = Math.round(outputWidth / aspectRatio);
  } else if (outputHeight && !outputWidth) {
    aspectRatio = aspectRatio || imageWidth / imageHeight;
    outputWidth = Math.round(outputHeight * aspectRatio);
  } else if (!outputHeight && !outputWidth && !aspectRatio) {
    outputHeight = imageHeight;
    outputWidth = imageWidth;
  } else if (!outputHeight && !outputWidth && aspectRatio) {
    outputHeight = Math.round(imageWidth / aspectRatio);
    outputWidth = imageWidth;
  }
  aspectRatio = aspectRatio || outputWidth / outputHeight;
  if (outputWidth / outputHeight >= 1.7) {
    if (debug) {}
    threshold.width = outputHeight;
    threshold.height = outputHeight;
  }
  var widths = [],
    heights = [],
    aspects = [],
    xCords = [],
    yCords = [],
    xwCords = [],
    yhCords = [],
    exactMatches = [],
    availableCrops = [],
    squareSeed = [];
  for (var i = 0; i < crops.length; i++) {
    if (crops[i].seed) {
      if (outputWidth > outputHeight && crops[i].outputWidth < crops[i].outputHeight) {} else if (crops[i].size === "square") {
        squareSeed.push(crops[i]);
      } else {
        availableCrops.push(crops[i]);
        widths.push(crops[i].outputWidth);
        heights.push(crops[i].outputHeight);
        aspects.push(crops[i].outputWidth / crops[i].outputHeight);
        xCords.push(crops[i].x / 100 * imageWidth);
        yCords.push(crops[i].y / 100 * imageHeight);
        xwCords.push((crops[i].x + crops[i].width) / 100 * imageWidth);
        yhCords.push((crops[i].y + crops[i].height) / 100 * imageHeight);
      }
    }
    if (crops[i].outputWidth === outputWidth && crops[i].outputHeight === outputHeight) {
      exactMatches.push(i);
    }
  }
  var crop = {};
  var imageOuterBounds = {
    x: xCords && xCords.length > 0 ? Math.min.apply(null, xCords) : 0,
    xw: xwCords && xwCords.length > 0 ? Math.max.apply(null, xwCords) : imageWidth,
    y: yCords && yCords.length > 0 ? Math.min.apply(null, yCords) : 0,
    yh: yhCords && yhCords.length > 0 ? Math.max.apply(null, yhCords) : imageHeight
  };
  imageOuterBounds.width = imageOuterBounds.xw - imageOuterBounds.x;
  imageOuterBounds.height = imageOuterBounds.yh - imageOuterBounds.y;
  var imageInnerBounds = {
    x: Math.max.apply(null, xCords),
    xw: Math.min.apply(null, xwCords),
    y: Math.max.apply(null, yCords),
    yh: Math.min.apply(null, yhCords)
  };
  imageInnerBounds.width = imageInnerBounds.xw - imageInnerBounds.x;
  imageInnerBounds.height = imageInnerBounds.yh - imageInnerBounds.y;
  if (exactMatches.length > 0) {
    crop.width = crops[exactMatches[0]].width / 100 * imageWidth;
    crop.height = crops[exactMatches[0]].height / 100 * imageHeight;
    crop.x = crops[exactMatches[0]].x / 100 * imageWidth;
    crop.y = crops[exactMatches[0]].y / 100 * imageHeight;
    crop.aspectRatio = crops[exactMatches[0]].width * imageWidth / (crops[exactMatches[0]].height * imageHeight);
    if (exactMatches.length !== 1) {}
  } else if (exactMatches.length < 1 && options.hard) {
    return {
      error: "No crops returned"
    };
  } else if (aspectRatio === 1 && outputWidth > threshold.square && squareSeed.length > 0) {
    crop.width = squareSeed[0].width / 100 * imageWidth;
    crop.height = squareSeed[0].height / 100 * imageHeight;
    crop.x = squareSeed[0].x / 100 * imageWidth;
    crop.y = squareSeed[0].y / 100 * imageHeight;
    crop.aspectRatio = aspectRatio;
  } else if (availableCrops.length > 0) {
    crop = getClosestSize(crop, widths, heights, outputHeight, outputWidth, availableCrops, imageInnerBounds, imageOuterBounds, imageHeight, imageWidth, threshold, debug);
  } else {
    var updateOutputWidth = imageWidth;
    var updateOutputHeight = imageHeight;
    if (updateOutputWidth > imageWidth) {
      updateOutputHeight = imageWidth * (aspectRatio || imageWidth / imageHeight);
    }
    if (updateOutputHeight > imageHeight) {
      var imageAspectRatioHeight = aspectRatio || imageWidth / imageHeight;
      updateOutputWidth = updateOutputHeight * imageAspectRatioHeight;
    }
    var offsetX = (imageWidth - updateOutputWidth) / 2 || 0;
    var offsetY = (imageHeight - updateOutputHeight) / 2 || 0;
    crop.width = updateOutputWidth;
    crop.height = updateOutputHeight;
    crop.x = offsetX > 0 ? offsetX : 0;
    crop.y = offsetY > 0 ? offsetY : 0;
    crop.aspectRatio = updateOutputHeight / updateOutputWidth;
  }
  if (debug) {
    if (crop.y < imageOuterBounds.y) {}
    if (crop.y + crop.height > imageOuterBounds.yh) {}
    if (crop.x < imageOuterBounds.x) {}
    if (crop.x + crop.width > imageOuterBounds.xw) {}
  }
  crop.outputWidth = outputWidth;
  crop.outputHeight = outputHeight;
  crop.scale = outputScale;
  return crop;
}
function djSmartCrop(options) {
  const crop = smartCropAlgorithm(options);
  const result = {
    x: Math.round(crop.x) || 0,
    y: Math.round(crop.y) || 0,
    cropWidth: Math.round(crop.width),
    cropHeight: Math.round(crop.height),
    width: Math.round(crop.outputWidth * (crop.scale || 1)),
    height: Math.round(crop.outputHeight * (crop.scale || 1)),
    cropId: crop.id
  };
  if (crop.padding) {
    result.padding = crop.padding;
  } else {
    if (result.x + result.cropWidth > options.imageWidth) {
      result.cropWidth = options.imageWidth - result.x;
    }
    if (result.y + result.cropHeight > options.imageHeight) {
      result.cropHeight = options.imageHeight - result.y;
    }
  }
  return {
    params: options,
    result
  };
}
const getCrops = (contextMetadata, imageId) => {
  let crops = '[{}]';
  if (contextMetadata[`dj_smart_crop_${imageId}`]) {
    crops = contextMetadata[`dj_smart_crop_${imageId}`];
  } else if (contextMetadata[`dj_smart_crop_${imageId}_length`]) {
    const cropLength = Number(contextMetadata[`dj_smart_crop_${imageId}_length`]) || 0;
    if (cropLength > 0) {
      crops = '';
      for (let i = 0; i < cropLength; i++) {
        crops += contextMetadata[`dj_smart_crop_${imageId}_${i}`];
      }
    }
  }
  return JSON.parse(crops);
};
const getDimensions = (options, defaultWidth) => {
  const width = parseInt(options.width) || false;
  const height = parseInt(options.height) || false;
  let aspectRatio = parseFloat(options.size) || false;
  const dpr = parseFloat(options.dpr) || false;
  const array = [];
  if (dpr) array.push(`dpr_${dpr}`);
  if (aspectRatio) {
    aspectRatio = round(aspectRatio, 3);
    options.ar = aspectRatio;
  }
  if (width) {
    array.push(`w_${width}`);
  }
  if (height) {
    array.push(`h_${height}`);
    options.height = height;
  }
  if (!width && !height) array.push(`w_${defaultWidth}`);
  if ((!width || !height) && aspectRatio) array.push(`ar_${aspectRatio}`);
  return array;
};
export function fnSelectRenderer(getDocument, getContext) {
  let logError = '';
  try {
    const content = JSON.parse(getDocument());
    const context = getContext();
    const imageId = context.resource;
    const contextTransformation = context.transformation || '';
    logError += `contextTransformation: ${contextTransformation};`;
    const regex = /\$([a-zA-Z]{0,})_!(([a-zA-Z0-9.?]{0,})(_[0-9]{0,}x[0-9]{0,})?)/gi;
    let options = {};
    let match;
    do {
      match = regex.exec(contextTransformation);
      if (match) {
        if (match[1] === 'host') {
          options[match[1]] = match[2];
          options.property = MD5_HASH[match[2]];
        } else if (['pixelratio', 'pixel_ratio'].includes(match[1].toLowerCase())) {
          options.dpr = parseFloat(match[2]);
        } else {
          options[match[1]] = match[2];
        }
      }
    } while (match);
    if (options.height && options.width && options.size && !options.size.startsWith('custom_')) {
      delete options.size;
    }
    const PROPERTY_CONFIG = {
      ...COMMON_CONFIG,
      ...(DEFAULT_PROPERTY_CONFIG[options.property] || {})
    };
    if (options.named) {
      const propertySizes = PROPERTY_CONFIG.SIZES;
      const size = {
        ...propertySizes,
        ...COMMON_NAMED_SIZES
      }[options.named.toLowerCase()];
      if (size) {
        delete options.width;
        delete options.height;
        if (size.width) options.width = size.width;
        if (size.height) options.height = size.height;
        if (size.dpr) options.dpr = size.dpr;
        delete options.named;
      }
    }
    logError += `options=${JSON.stringify(options)};`;
    let item = content[0];
    let contextMetadata = item?.context?.custom || {};
    let transformation = '';
    let cropImage = '';
    let customCrop = false;
    const getContextValue = key => {
      return contextMetadata[`${key}_${imageId}`] || contextMetadata[key];
    };
    const THUMB_SIZE_WIDTH = PROPERTY_CONFIG.DEFAULT_THUMB_SIZE_WIDTH;
    const THUMB_SIZE_HEIGHT = PROPERTY_CONFIG.DEFAULT_THUMB_SIZE_HEIGHT;
    const transformationType = getContextValue('transformation_type');
    const MIN_THUMB_DPR = PROPERTY_CONFIG.DEFAULT_MIN_THUMB_DPR || null;
    const MIN_DPR = PROPERTY_CONFIG.DEFAULT_MIN_DPR || null;
    const disableThumbnailsCrop = getContextValue('disable_thumbnails_crop');
    const disableThumbnails = typeof disableThumbnailsCrop === 'string' ? disableThumbnailsCrop === 'true' : disableThumbnailsCrop;
    const matchesThumbSize = options.width <= THUMB_SIZE_WIDTH || !options.width && options.height <= THUMB_SIZE_HEIGHT;
    const isThumbnail = disableThumbnails ? false : matchesThumbSize;
    const minDpr = isThumbnail ? parseFloat(MIN_THUMB_DPR) : parseFloat(MIN_DPR);
    if (options.dpr || !options.dpr && minDpr) {
      options.dpr = options.dpr < minDpr ? minDpr : options.dpr || minDpr;
    }
    let crop = isThumbnail ? PROPERTY_CONFIG.DEFAULT_THUMB_CROP : PROPERTY_CONFIG.DEFAULT_CROP;
    let ext = options.ext || PROPERTY_CONFIG.DEFAULT_EXT;
    let quality = getContextValue('quality') || PROPERTY_CONFIG.DEFAULT_QUALITY;
    let sharpen = getContextValue('sharpen') || null;
    if (sharpen) {
      quality = `${quality}/e_${sharpen}`;
    }
    let improve = getContextValue('improve') || null;
    if (improve) {
      quality = `${quality}/e_${improve}`;
    }
    let gravity = PROPERTY_CONFIG.DEFAULT_GRAVITY;
    const contextGravity = getContextValue('gravity');
    const graphicType = (item?.metadata || [{}]).find(obj => obj.external_id === 'graphic_type');
    if (graphicType?.value === 'commerce') {
      gravity = contextGravity || 'center';
    }
    const contextCrop = getContextValue('crop');
    if (contextCrop) {
      const cropOptions = contextCrop.split(',');
      cropImage = `c_crop,x_${cropOptions[0]},y_${cropOptions[1]},w_${cropOptions[2]},h_${cropOptions[3]}/`;
    }
    if (contextGravity) {
      gravity = contextGravity;
    }
    if (options.size && options.size.startsWith('custom_') || options.width && options.height) {
      customCrop = options.size ? contextMetadata[options.size] : getContextValue(`custom_${options.width}x${options.height}`);
    }
    if (customCrop) {
      const outputSize = options.size ? options.size.replace('custom_', '').split('x') : [options.width, options.height];
      if (customCrop) {
        transformation = `c_crop,${customCrop}/c_scale,w_${outputSize[0]},h_${outputSize[1]}`;
      } else {
        transformation = `c_fill,g_${gravity},w_${outputSize[0]},h_${outputSize[1]}`;
      }
    } else if (options.named && item.format !== 'gif') {
      let background = getContextValue('background');
      if (transformationType === 'dynamic' && !background) {
        background = '#FFFFFF';
      }
      transformation = `${cropImage}t_${options.named}${background?.substring(0, 1) === '#' ? `,b_${background.replace('#', 'rgb:')}` : ''}`;
    } else {
      logError += `transformation_type: ${transformationType};`;
      let dimensions = getDimensions(options, PROPERTY_CONFIG.DEFAULT_WIDTH);
      let contextBackground = getContextValue('background');
      if (item.format === 'gif') {
        gravity = 'center';
        ext = options.ext || 'auto:animated';
        if (options.width > item.width) {
          options.width = item.width;
          if (options.height) {
            delete options.height;
          }
        }
        const gifOptions = {
          ...options,
          ...{
            dpr: 1
          }
        };
        dimensions = getDimensions(gifOptions, item.width);
        quality = 'auto:best';
        crop = 'fill';
        if (!contextBackground) {
          contextBackground = 'transparent';
        }
      }
      const background = contextBackground ? contextBackground.replace('#', 'rgb:') : null;
      switch (transformationType) {
        case 'resize':
          transformation = `${cropImage}`;
          if (!background) {
            transformation += `c_pad,${dimensions.join(',')}`;
          } else if (background && background.substring(0, 4) === 'rgb:') {
            transformation += `c_pad,${dimensions.join(',')},b_${background}`;
          } else if (item.public_id && background === 'blur') {
            transformation += `c_pad,${dimensions.join(',')}/c_fill,u_${item.public_id.split('/').join(':')},e_blur:1000,${dimensions.join(',')}`;
          } else if (background === 'gen_fill') {
            const [,, cropWidth, cropHeight] = (getContextValue('crop') || '').split(',');
            const ar = options.ar || options.width / options.height || cropWidth / cropHeight || item.width / item.height;
            transformation += `c_pad,ar_${ar},b_gen_fill/${dimensions.join(',')}`;
          } else {
            transformation += `c_pad,${dimensions.join(',')},b_${background}`;
          }
          break;
        case 'djSmartCrop':
          const seedCrops = getCrops(contextMetadata, imageId);
          const smartCrop = djSmartCrop({
            aspectRatio: parseFloat(options.size) || null,
            freeze: false,
            optimize: true,
            outputHeight: options.height || null,
            outputScale: parseFloat(options.pixelratio) || parseFloat(options.pixel_ratio) || parseFloat(options.dpr) || 1,
            outputWidth: options.width || null,
            seedCrops,
            originalImageDimension: null,
            sizedImage: false,
            renderOriginal: false,
            imageHeight: item.height,
            imageWidth: item.width
          });
          item.smartcrop = smartCrop;
          const scTransformation = `c_crop,x_${smartCrop.result.x},y_${smartCrop.result.y},w_${smartCrop.result.cropWidth},h_${smartCrop.result.cropHeight}`;
          transformation = `${scTransformation}/c_limit,${dimensions.join(',')}`;
          break;
        case 'dynamic':
          if (!options.width && !options.height) {
            options.width = PROPERTY_CONFIG.DEFAULT_WIDTH;
          }
          const seedCropsArray = [];
          content.forEach(image => {
            let imageContextMetadata = image?.context?.custom || {};
            const scales = imageContextMetadata?.dynamic_scale ? imageContextMetadata?.dynamic_scale.split(',') : [];
            const xScale = Number(imageContextMetadata?.x_scale || 1);
            const imageWidth = Math.round(image.width / xScale || image.width);
            const imageHeight = Math.round(image.height / xScale || image.height);
            seedCropsArray.push({
              id: image.public_id,
              outputWidth: imageWidth,
              outputHeight: imageHeight,
              x: 0,
              y: 0,
              scale: 1
            });
            if (!scales.length && image.width === 1280 && image.height === 1280) {
              const alreadyHasSize = seedCropsArray.find(i => i.width === 480 && i.height === 480);
              !alreadyHasSize && seedCropsArray.push({
                id: image.public_id,
                outputWidth: 480,
                outputHeight: 480,
                x: 0,
                y: 0,
                scale: 1280 / 480
              });
            }
            scales.length && scales.forEach(scale => {
              if (scale !== '1') {
                seedCropsArray.push({
                  id: image.public_id,
                  outputWidth: Math.round(imageWidth * scale),
                  outputHeight: Math.round(imageHeight * scale),
                  x: 0,
                  y: 0,
                  scale: scale
                });
              }
            });
          });
          const smartCropOptions = {
            sizedImage: true,
            outputWidth: Number(options.width) || null,
            outputHeight: Number(options.height) || null,
            outputScale: 1,
            aspectRatio: Number(options.size) || null,
            seedCrops: seedCropsArray
          };
          const selectedImage = chooseCrop(smartCropOptions);
          item = content.find(image => image.public_id === selectedImage.id);
          if (item.format === 'svg') {
            ext = 'png';
          }
          const scaledWidth = Math.round(item.width * selectedImage.scale);
          let width = scaledWidth;
          let dpr = 1;
          if (item.format !== 'gif') {
            width = scaledWidth * (options.dpr > 2 ? options.dpr : 2);
            dpr = options.dpr > 2 ? options.dpr : 2;
          }
          transformation = `w_${width > 2560 ? 2560 : width},dpr_${dpr}/a_0/${['c_lpad', getDimensions({
            ...options,
            dpr: false
          }, item.width)].join(',')},${background ? `b_${background}` : 'b_rgb:FFFFFF'}`;
          quality = `auto:best`;
          break;
        default:
          const coordinates = getContextValue(`coordinates`);
          const thumbGravity = getContextValue(`gravity_thumb`);
          if (thumbGravity && crop === PROPERTY_CONFIG.DEFAULT_THUMB_CROP) {
            gravity = thumbGravity;
          } else if (coordinates) {
            const [,, coordinatesWidth,, centerX, centerY] = coordinates.split(',');
            const [cropX, cropY, cropWidth, cropHeight] = (getContextValue('crop') || '').split(',');
            const ar = options.ar || options.width / options.height || cropWidth / cropHeight || item.width / item.height;
            const getWidth = dimensions.filter(value => /^w_/.test(value))[0]?.split('_')[1];
            const getHeight = dimensions.filter(value => /^h_/.test(value))[0]?.split('_')[1];
            let updatedDimensions = '';
            if (getHeight && !getWidth && crop !== PROPERTY_CONFIG.DEFAULT_THUMB_CROP) {
              updatedDimensions = `,h_${Number(getHeight) * 2}`;
            } else {
              updatedDimensions = `,w_${Number(getWidth && crop !== PROPERTY_CONFIG.DEFAULT_THUMB_CROP ? getWidth * 2 : coordinatesWidth)}`;
            }
            gravity = `xy_center,x_${centerX - (cropX || 0)},y_${centerY - (cropY || 0)}${updatedDimensions}${ar ? `,ar_${ar}` : ''}/c_fill`;
          }
          if (!coordinates && crop === PROPERTY_CONFIG.DEFAULT_THUMB_CROP && (gravity === 'auto' || gravity === undefined)) {
            const getDpr = dimensions.filter(value => /^dpr_/.test(value))[0];
            let dimensionsString = dimensions.join(',');
            if (getDpr) {
              dimensionsString = dimensionsString.replace(`${getDpr},`, '');
            }
            transformation = `${cropImage}`;
            if (getDpr) {
              transformation += `${getDpr}/`;
            }
            transformation += `if_fc_gt_0/c_thumb,g_faces,z_0.6,${dimensionsString}`;
            transformation += `/if_else/c_${PROPERTY_CONFIG.DEFAULT_CROP},g_auto,${dimensionsString}`;
            transformation += `/if_end`;
            if (background) {
              transformation += `/b_${background}`;
            }
          } else {
            const transformationArray = [`c_${crop}`, `g_${gravity}`, ...dimensions];
            if (background) transformationArray.push(`b_${background}`);
            transformation = `${cropImage}${transformationArray.join(',')}`;
          }
      }
    }
    let overlay = getContextValue('overlay');
    if (overlay) {
      transformation += `/${overlay}`;
    }
    if (content.length) logError += `content_length: ${content.length};`;
    if (options.size) logError += `is_custom: ${options.size && options.size.startsWith('custom_')};`;
    if (options.named) logError += `is_named: ${options.named};`;
    if (ext) logError += `ext: ${ext};`;
    logError += `transformation: "${item.transformation}";`;
    const canAccessImage = PROPERTY_CONFIG.LOCATIONS.map(location => {
      return item.public_id.startsWith(location);
    }).some(Boolean);
    const isActive = (item?.metadata || [{}]).find(obj => obj.external_id === 'active');
    if (canAccessImage && isActive?.value === 'true') {
      item.transformation = `${transformation}/f_${ext},q_${quality}`;
      return item;
    } else {
      throw new Error(`Invalid Image:  {
          "isActive": "${JSON.stringify(isActive)} , ${typeof isActive?.value} ${isActive?.value}",
          "host": "${options.host}",
          "property": "${options.property}",
          "public_id": "${item.public_id}", 
          "imageLocation": "${item.public_id.split('/')[0]}", 
          "contextTransformation": "${contextTransformation}",
          "item": ${JSON.stringify(item)},
          "LOCATIONS": "${PROPERTY_CONFIG.LOCATIONS}"
        }`);
    }
  } catch (error) {
    throw error;
  }
}
