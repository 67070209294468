import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  FormLabel,
  Group,
  IconCaretDown,
  IconBook,
  Radio,
  Token,
  Typography,
  IconInfo,
} from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { SearchOptionsType, SearchStatusType } from '../../../types';

type Statuses = {
  [key in Exclude<SearchStatusType, null>]: { label: string; token?: React.ReactNode; disabled?: boolean };
};

type StatusFilterProps = {
  currentOptions: SearchOptionsType;
  onSearch: (options?: SearchOptionsType) => void;
};

const StatusFilter = ({ currentOptions, onSearch }: StatusFilterProps) => {
  const { userAccess } = useAuth();
  const isDeveloper = userAccess(['developer', 'app_admin']);
  const { statusFilter } = currentOptions;
  const [status, setStatus] = useState<SearchStatusType>(statusFilter || null);

  const statuses: Statuses = {
    published: { label: 'Published' },
    unpublished: { label: 'Unpublished' },
    placed: {
      label: 'Used in Article',
      token: isDeveloper ? <Token icon={IconInfo}>BETA</Token> : <Token color="ice">Coming Soon</Token>,
      disabled: !isDeveloper,
    },
  };

  useEffect(() => {
    setStatus(statusFilter as SearchStatusType);
  }, [statusFilter]);

  return (
    <Dropdown data-testid="status-filter">
      <Dropdown.Trigger>
        <Button tertiary icon={IconBook} active={!!status}>
          {status && statuses[status] && statusFilter ? statuses[status].label : 'Status'}
          <IconCaretDown style={{ marginLeft: 'var(--st-spacer-xs)' }} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        style={{
          boxSizing: 'border-box',
          minWidth: '15rem',
        }}
        padding={{ all: 'none' }}
      >
        <Group gap="xs" margin={{ all: 'sm' }} direction="column">
          {Object.keys(statuses).map((key) => {
            return (
              <FormLabel
                data-testid="status-option"
                key={key}
                label={
                  <>
                    {key !== null && statuses[key as Exclude<SearchStatusType, null>].label}{' '}
                    {statuses[key as Exclude<SearchStatusType, null>].token}
                  </>
                }
                labelPosition="right"
                fullWidth
              >
                <Radio
                  checked={status === key}
                  name="imStatusRadio"
                  defaultChecked={status === key}
                  disabled={statuses[key as Exclude<SearchStatusType, null>].disabled}
                  onChange={() => {
                    setStatus(key as SearchStatusType);
                    onSearch({ statusFilter: key as SearchStatusType });
                  }}
                />
              </FormLabel>
            );
          })}
          <Group.Item fullWidth>
            <Token
              data-testid="clear-published-status"
              componentEl={Button}
              size="sm"
              tertiary
              onClick={() => {
                setStatus(null);
                onSearch({ statusFilter: null });
              }}
            >
              Clear
            </Token>
          </Group.Item>
        </Group>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default StatusFilter;
