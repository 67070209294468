import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Group,
  Steps,
  IconArrowRight,
  IconCaretLeft,
  IconSend,
  Responsive,
  IconPlus,
  Subnav,
} from '@screentone/core';

import { useAssetManager } from '../../../hooks/useAssetManager';
import { useConfig } from '../../../hooks/useConfig';
import useUpload from '../../../hooks/useUpload';

import { getLastPublished, localStorageHelper } from '../../../utils/helpers';
import { constants, iframe } from '../../../utils';

import styles from './UploadNavbar.module.css';
import { ImageType } from '../../../types';

type UploadNavbarTypes = {
  /** text for left button */
  buttonLeftContent?: string;
  /** text for right button */
  buttonRightContent?: string;
  /** active step */
  stepActive?: number;
  /** completed steps */
  stepComplete?: number;
  /** event handler for left button. Button will be disabled if not provided */
  onButtonLeft?: (() => void) | null;
  /** event handler for right button. Button will be disabled if not provided */
  onButtonRight?: (() => void) | null;
  /** status of upload process */
  status: string[];
  type?: string;
  response?: any;
};

function UploadNavbar({
  buttonLeftContent = 'Back',
  buttonRightContent = 'Publish',
  stepActive = 1,
  stepComplete = 0,
  onButtonLeft,
  onButtonRight,
  status,
  response,
  type,
}: UploadNavbarTypes) {
  const {
    authFetch,
    session: { env, property, appUrl },
  } = useConfig();

  const { actions, state, dispatch } = useUpload();
  const navigate = useNavigate();
  const { setLatestUploads, getLastUploadedImages, getImage } = useAssetManager();
  const latestUploads = getLastUploadedImages();
  const images = latestUploads.map((image) => getImage(image));
  const [backToUploadList, setBackToUploadList] = useState('false');
  const [firstImage, setFirstImage] = useState<any>(response.length ? response[0] : null);
  const isComplete = status.length ? status.every((stat) => stat === 'complete') : false;
  if (isComplete && (status.length === 1 || type === 'dynamic')) {
    const image = state?.upload?.response[0];
    if (type === 'dynamic') {
      const all_resources = structuredClone(state?.upload?.response);
      image.additional_resources = all_resources;
    }
    navigate(`/${property}/image/${state?.upload?.response[0].asset_id}`, { state: { image } });
  }
  const onAdd = () => {
    // missing ID's
    iframe.sendToSource({ image: response, ids: latestUploads, env, property, appUrl, authFetch });
  };

  const showAddToSource = iframe.isFrame() && iframe.sendImageArray() && isComplete && status.length > 1;

  useEffect(() => {
    if (isComplete) {
      setLatestUploads(response);
      dispatch({ type: actions.NAVIGATION.FORWARD });
    }
  }, [isComplete]);

  useEffect(() => {
    const backToUploadList = localStorage.getItem(`${property}:backToUploadList`) || 'false';
    setBackToUploadList(backToUploadList);
  }, [latestUploads, localStorage.getItem(`${property}:backToUploadList`)]);

  useEffect(() => {
    if (images.length) {
      setFirstImage(images[0]);
    }
  }, [images]);

  const fieldFormValidation = state.validation.some((fields) =>
    Object.values(fields).some((field) => typeof field === 'string'),
  );

  if (backToUploadList === 'true') {
    stepActive = 0;
    stepComplete = 2;
  }

  return (
    <Subnav className={styles.wrapper}>
      <Responsive xs>
        <Group align="space-between" fullWidth>
          <Button
            icon={IconCaretLeft}
            tertiary
            onClick={onButtonLeft}
            disabled={backToUploadList === 'true' || !onButtonLeft}
          >
            {buttonLeftContent}
          </Button>
          <Steps active={stepActive} complete={stepComplete}>
            <Steps.Item number={1} />
            <Steps.Item number={2} />
          </Steps>
          <Button
            primary
            icon={isComplete || backToUploadList === 'true' ? IconArrowRight : IconSend}
            iconPosition="right"
            onClick={onButtonRight}
            disabled={backToUploadList === 'false' && (!onButtonRight || status.includes('loading'))}
          />
        </Group>
      </Responsive>
      <Responsive sm md lg>
        <Group align="space-between" fullWidth>
          <Button
            icon={IconCaretLeft}
            tertiary
            onClick={onButtonLeft}
            disabled={backToUploadList === 'true' || !onButtonLeft}
          >
            {buttonLeftContent}
          </Button>
          <Steps active={stepActive} complete={stepComplete}>
            <Steps.Item>Upload</Steps.Item>
            <Steps.Item>Publish</Steps.Item>
          </Steps>
          <Group inline>
            <Button
              data-testid="top-right-btn"
              size={iframe.isFrame() ? 'sm' : undefined}
              primary={!showAddToSource && !isComplete && backToUploadList === 'false'}
              secondary={showAddToSource || isComplete || backToUploadList === 'true'}
              onClick={onButtonRight}
              disabled={
                backToUploadList === 'false' && (!onButtonRight || status.includes('loading') || fieldFormValidation)
              }
            >
              {isComplete || backToUploadList === 'true' ? constants.MY_IMAGES : buttonRightContent}
            </Button>
            {isComplete || backToUploadList === 'true' ? (
              <Button
                data-testid="top-right2-btn"
                size={iframe.isFrame() ? 'sm' : undefined}
                primary
                icon={IconArrowRight}
                iconPosition="right"
                componentEl={Link}
                disabled={!isComplete && backToUploadList === 'false'}
                state={{ uploads: response }}
                to={`/${property}/batch/${firstImage?.asset_id}/edit/${getLastPublished(firstImage, property)}`}
              >
                Adjust Images
              </Button>
            ) : null}
            {!!showAddToSource && (
              <Button data-testid="add-all-to-article-btn" size="sm" icon={IconPlus} primary onClick={onAdd}>
                Add all to Article
              </Button>
            )}
          </Group>
        </Group>
      </Responsive>
    </Subnav>
  );
}

export default UploadNavbar;
