{
  "KEY": "wsj",
  "RELATED_PROPERTIES": ["wsjopinion"],
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://images-wsj.dev.djtools.dowjones.io/"
    },
    "dev": {
      "default": "https://images-wsj.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.wsj.net/"
    },
    "prd": {
      "default": "https://images.wsj.net/"
    }
  },
  "EAGER": {
    "cldDefault": [
      [
        { "c": "fill", "g": "$G", "h": 341, "w": 607, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "$G", "h": 400, "w": 600, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "$G", "h": 351, "w": 527, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "fill", "g": "$G", "h": 245, "w": 368, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 191, "w": 287, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "thumb", "g": "$G", "h": 138, "w": 207, "dpr": 2 },
        { "f": "avif", "q": "auto:eco" }
      ]
    ],
    "resize": [
      [
        { "c": "pad", "h": 341, "w": 607, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 400, "w": 600, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 351, "w": 527, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 245, "w": 368, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 191, "w": 287, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ],
      [
        { "c": "pad", "h": 138, "w": 207, "dpr": 2 },
        { "c": "fill", "b": "$BG" },
        { "f": "avif", "q": "auto:eco" }
      ]
    ]
  },
  "SLACK": {
    "local": "C02PRBFBBH7",
    "dev": "C02PRBFBBH7",
    "int": "C02PRBFBBH7",
    "prd": "C02Q4SVG0U8"
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "3:2:thumb": {
      "isThumbnail": true,
      "ar": 1.5,
      "label": "Thumb 3:2"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "5:3": {
      "ar": 1.67,
      "label": "5:3"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    },
    "4:5": {
      "ar": 0.8,
      "label": "4:5"
    },
    "2:3": {
      "ar": 0.67,
      "label": "2:3"
    },
    "9:16": {
      "ar": 0.56,
      "label": "9:16"
    }
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    },
    {
      "key": "4:5",
      "label": "4:5",
      "width": 1280,
      "height": 1600
    },
    {
      "key": "5:3",
      "label": "5:3",
      "width": 2000,
      "height": 1200
    }
  ],
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "1032",
    "dev": "1032",
    "int": "78a8",
    "prd": "7cbd"
  },
  "FORM_VALIDATION": ["headline", "caption", "credit", "datePhotographed", "contact", "slug"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "getty": {
      "label": "Getty",
      "folder": "wires/Getty",
      "subSources": {
        "afp": {
          "label": "AFP",
          "folder": "wires/AFP"
        }
      }
    },
    "ap": {
      "label": "AP",
      "folder": "wires/AP"
    },
    "epa": {
      "label": "EPA",
      "folder": "wires/EPA"
    },
    "zuma": {
      "label": "Zuma",
      "folder": "wires/Zuma"
    },
    "bloomberg": {
      "label": "Bloomberg",
      "folder": "wires/Bloomberg"
    },
    "reuters": {
      "label": "Reuters",
      "folder": "wires/Reuters"
    },
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "TAGS": {
    "TOPWIRES": {
      "key": "TopWires",
      "label": "Top Wires",
      "color": "ice",
      "icon": "award",
      "title": "Image Selected as Top Wires"
    },
    "SELECTS": {
      "key": "WSJSelects",
      "label": "WSJ Selects",
      "color": "ice",
      "icon": "star",
      "title": "Image Editorially Selected",
      "editable": true
    },
    "PRINT": {
      "key": "WSJPrint",
      "label": "Used In Print",
      "color": "gray",
      "icon": "newspaper",
      "title": "Image Used In Print",
      "editable": true
    }
  },
  "ADVANCED_SEARCH": {
    "show_advanced": true,
    "keywords": [
      { "label": "Top Wires", "name": "TopWires" },
      { "label": "WSJ Selects", "name": "WSJSelects" },
      { "label": "Used In Print", "name": "WSJPrint" }
    ],
    "region": [
      { "label": "Tri-State Area", "name": "GNY" },
      { "label": "Domestic (US)", "name": "Domestic" },
      { "label": "International", "name": "International" }
    ],
    "categories": [
      { "label": "Sport", "name": "Sport" },
      { "label": "Entertainment", "name": "Entertainment" }
    ]
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ],
  "DOWNLOADS": [
    {
      "label": "Campaign Monitor Newsletter",
      "sizes": [
        {
          "label": "900px 3:2 JPEG",
          "width": 900,
          "aspect_ratio": 1.5,
          "format": "jpg",
          "name": "newsletters_3_2_#publishedId#"
        },
        {
          "label": "900px Square JPEG",
          "width": 900,
          "aspect_ratio": 1,
          "format": "jpg",
          "name": "newsletters_square_#publishedId#"
        }
      ]
    }
  ]
}
