import React from 'react';
import { Group, Typography } from '@screentone/core';
import { parse, format } from '@screentone/addon-calendar';

import ImageTokenList from '../../ImageTokenList';
import Tags from '../Tags';

import { formatBytes, getContextValue } from '../../../utils/helpers';

import type { ImageType, PropertyType } from '../../../types';

import styles from './MetadataDescription.module.css';

interface IMetadata {
  image: ImageType;
  setImage?: (image: ImageType) => void;
  direction?: 'row' | 'column';
  hideLabels?: boolean;
  previewType?: string;
  property?: PropertyType;
  publishedId?: string;
}

function MetadataDescription({ image, setImage, direction = 'row', hideLabels, previewType, property, publishedId }: IMetadata) {
  image.additional_resources?.forEach((resource: any) => {
    if (resource.metadata.import_source_name.toUpperCase() === previewType) {
      image = resource;
    }
  });
  
  const headline = image.metadata?.headline;
  const caption = image.metadata?.caption || null;
  const city = image.metadata?.city || null;
  const country = image.metadata?.country || null;
  const credit = image.metadata?.credit || null;
  const datePhotographed = image.metadata?.date_photographed || null;
  const xScale = getContextValue(image as ImageType, 'x_scale', property, publishedId) || 0;

  const setImageHandler = (updatedImage: ImageType) => {
    setImage && setImage(updatedImage);
  };

  const dateText = datePhotographed
    ? format(parse(datePhotographed, 'yyyy-MM-dd', new Date()), 'eeee, MMMM d, yyyy')
    : null;

  const dtProps = { size: 'sm', weight: 'bold' };
  const ddProps = { size: 'sm' };
  const dtSecondaryProps = { ...dtProps, color: 'asphalt' };
  const ddSecondaryProps = { ...ddProps, color: 'asphalt' };

  return (
    <Group direction="column" gap="sm">
      <Typography data-testid="headline" variant="header3" title={headline} truncate>
        {headline}
      </Typography>

      <Typography data-testid="caption" margin={{ bottom: 'sm' }}>
        {caption ? caption : <em>No Caption</em>}
      </Typography>

      {/* Metadata BLOCK START */}
      <Group
        direction={direction}
        colgap="mlg"
        rowgap="xs"
        align="start"
        componentEl="dl"
        className={styles.metadata}
        margin={{ bottom: direction === 'row' ? 'none' : 'xs' }}
      >
        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtProps}>Created:</Typography>
          </dt>
          <dd>
            <Typography data-testid="date" {...ddProps}>
              {dateText ? dateText : <em>Unknown</em>}
            </Typography>
          </dd>
        </Group>

        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtProps}>Location:</Typography>
          </dt>
          <dd>
            {city || country ? (
              <Typography data-testid="location" {...ddProps}>
                {city && country ? `${city}, ${country}` : `${city || country}`}
              </Typography>
            ) : (
              <Typography data-testid="date" {...ddProps}>
                <em>Unknown</em>
              </Typography>
            )}
          </dd>
        </Group>

        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtProps} data-testid="credit-field">
              Credit:
            </Typography>
          </dt>
          <dd>
            <Typography data-testid="credit" {...ddProps}>
              {credit || credit !== 'Uncredited' ? credit : <em>Uncredited</em>}
            </Typography>
          </dd>
        </Group>
      </Group>

      <Group direction={direction} colgap="mlg" rowgap="xs" align="start" componentEl="dl" className={styles.metadata}>
        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtSecondaryProps}>Type:</Typography>
          </dt>
          <dd>
            <Typography data-testid="graphic-type" {...ddSecondaryProps}>
              {image?.metadata?.graphic_type}
            </Typography>
          </dd>
        </Group>
        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtSecondaryProps}>Original Size:</Typography>
          </dt>
          <dd>
            <Typography data-testid="original-size" {...ddSecondaryProps}>
              {xScale && xScale > 0 ? `${image.width / xScale} x ${image.height / xScale} px ${xScale > 1 ? ` @${xScale}x` : ''}` : `${image.width} x ${image.height} px`}
            </Typography>
          </dd>
        </Group>
        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtSecondaryProps}>File Size:</Typography>
          </dt>
          <dd>
            <Typography data-testid="file-size" {...ddSecondaryProps}>
              {formatBytes(image.bytes)}
            </Typography>
          </dd>
        </Group>
        <Group direction="row" gap="xs">
          <dt>
            <Typography {...dtSecondaryProps}>Original Format:</Typography>
          </dt>
          <dd>
            <Typography data-testid="format-value" {...ddSecondaryProps}>
              {image.format.toUpperCase().replace('+XML', '')}
            </Typography>
          </dd>
        </Group>

        <Group direction="row" gap="xs">
          <dt>
            <Typography data-testid="source" {...dtSecondaryProps}>
              Source:
            </Typography>
          </dt>
          <dd>
            <Typography data-testid="source-value" {...ddSecondaryProps}>
              {image.metadata.import_source_type === 'wires' && image.metadata.import_source_name}
              {image.metadata.import_source_type === 'upload' && (image.isDynamic ? 'Dynamic Upload' : 'Uploaded')}
              {image.metadata.import_source_type === 'charts' && 'Charts'}
            </Typography>
          </dd>
        </Group>
      </Group>

      <Group gap="xs">
        <Tags image={image} setImage={setImage} />
      </Group>

      <Group gap="xs">
        <ImageTokenList image={image as ImageType} setImage={setImageHandler} hideLabels={hideLabels} />
      </Group>
    </Group>
  );
}

export default MetadataDescription;
