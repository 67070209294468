import React, { useCallback, useState } from 'react';
import {
  Wrapper,
  useSidebarState,
  Sidebar,
  Button,
  IconPlusCircle,
  Group,
  Typography,
  Responsive,
} from '@screentone/core';
import EditMetadataCard from '../../EditMetadataCard/EditMetadataCard';
import { EditImageMetaFormFooter, EditImageMetaForm } from '../../EditMetadataForm';
import SelectBar from '../../SelectBar/SelectBar';

import useUpload from '../../../hooks/useUpload';
import useUploadPositions from '../../../hooks/useUploadPositions';
import { constants } from '../../../utils';

import type UseUploadType from '../../../hooks/useUpload/types';
import type { GraphicType } from '../../../types';

type UploadFormProps = {
  /** function supplied by react-dropzone to handle opening the file system */
  handleBrowseFiles: React.MouseEventHandler<HTMLElement> | undefined;
};

const UploadForm = ({ handleBrowseFiles }: UploadFormProps) => {
  const { actions, dispatch, state, type } = useUpload();
  const [calendarError, setCalendarError] = useState('');
  const {
    status: mobileStatus,
    openSidebar: mobileOpenSidebar,
    closeSidebar: mobileCloseSidebar,
    sidebarProps: mobileSidebarProps,
    pageProps: mobilePageProps,
  } = useSidebarState({
    type: 'overlay',
    position: 'right',
    status: 'closed',
  });

  const { status, openSidebar, closeSidebar, sidebarProps, pageProps } = useSidebarState({
    position: 'right',
    status: 'closed',
    type: 'slide',
  });
  const { sidebarElem, sidebarY } = useUploadPositions();

  const openBulkEditForm = () => {
    // make sure that the bulk edit form's default values are up to date with the currently selected values
    dispatch({ type: actions.BULK_METADATA.REFRESH_BULK_EDIT_FORM });

    openSidebar();
  };

  const mobileOpenBulkEditForm = () => {
    // make sure that the bulk edit form's default values are up to date with the currently selected values
    dispatch({ type: actions.BULK_METADATA.REFRESH_BULK_EDIT_FORM });

    mobileOpenSidebar();
  };

  const updateImageData = useCallback(
    ({ index, img }: { index: number; img: HTMLImageElement }) => {
      dispatch({
        type: actions.RENDER.UPDATE_IMAGE_DATA,
        payload: {
          index,
          width: img.naturalWidth,
          height: img.naturalHeight,
        },
      });
    },
    [dispatch, actions]
  );
  return (
    <Wrapper>
      <div ref={sidebarElem} />
      <Responsive xs>
        {(matches: boolean) => {
          const propsSidebar = matches ? mobileSidebarProps : sidebarProps;
          const sidebarClose = matches ? mobileCloseSidebar : closeSidebar;
          const propsPage = matches ? mobilePageProps : pageProps;
          const sidebarStatus = matches ? mobileStatus : status;

          return (
            <>
              <Sidebar
                top={`${sidebarY}px` || null}
                bottom={null}
                width={matches ? '385px' : '300px'}
                type={matches ? 'overlay' : 'slide'}
                position="right"
                zIndex="minor"
                {...propsSidebar}
              >
                <Sidebar.Header>
                  <Typography data-testid="bulk-metadata-title" variant="label2" margin={{ all: 'md' }}>
                    Bulk Edit Metadata
                  </Typography>
                </Sidebar.Header>
                <Sidebar.Section>
                  <Wrapper padding={{ all: 'md' }}>
                    <Group direction="column">
                      <EditImageMetaForm
                        slug={state.bulk.metadata.slug}
                        caption={state.bulk.metadata.caption}
                        credit={state.bulk.metadata.credit}
                        contact={state.bulk.metadata.contact}
                        headline={state.bulk.metadata.headline}
                        graphicType={state.bulk.metadata.graphicType as GraphicType}
                        datePhotographed={state.bulk.metadata.datePhotographed}
                        oneTimeUse={state.bulk.metadata.oneTimeUse}
                        resizeOnly={state.bulk.metadata.resizeOnly}
                        uncredited={state.bulk.metadata.uncredited}
                        onChange={(metadata = {}) => {
                          dispatch({ type: actions.BULK_METADATA.EDIT, payload: { metadata } });
                        }}
                        disableOneTimeUse={false}
                        disableResize={false}
                        disableRequiredFields
                        calendarError={calendarError}
                        setCalendarError={setCalendarError}
                        background={state.bulk.metadata.background}
                        isUpload={true}
                      />
                    </Group>
                  </Wrapper>
                </Sidebar.Section>
                <Sidebar.Footer>
                  <EditImageMetaFormFooter
                    disableApplyButton={!state.bulk.selected.length || !!calendarError}
                    onApplyMetadata={() => {
                      dispatch({ type: actions.BULK_METADATA.APPLY_METADATA });
                      sidebarClose();
                    }}
                    onCancel={() => {
                      sidebarClose();
                      setCalendarError('');
                    }}
                  />
                </Sidebar.Footer>
              </Sidebar>
              <Sidebar.Page {...propsPage}>
                <Wrapper>
                  <SelectBar
                    selectedImages={state.bulk.selected}
                    totalImages={state.files.accepted.length}
                    stagingImages={
                      state.validation.filter(
                        (image) =>
                          image.caption !== false ||
                          image.contact !== false ||
                          image.credit !== false ||
                          (image.datePhotographed !== false && image.datePhotographed !== null) ||
                          image.headline !== false
                      ).length
                    }
                    status={sidebarStatus}
                    closeSidebar={sidebarStatus !== 'open' ? () => {} : sidebarClose}
                    openSidebar={matches ? mobileOpenBulkEditForm : openBulkEditForm}
                    onRemoveImage={() => {
                      dispatch({ type: actions.ALL.REMOVE_IMAGES, payload: { selected: state.bulk.selected } });
                    }}
                    onSelectAll={(selected) => {
                      if (selected) dispatch({ type: actions.BULK_METADATA.ADD_ALL });
                      else dispatch({ type: actions.BULK_METADATA.REMOVE_ALL });
                    }}
                  />
                  {state.render.length === state.files.accepted.length &&
                    state.metadata.length === state.files.accepted.length &&
                    state.validation.length === state.files.accepted.length &&
                    state.files.accepted.map((file, i) => (
                      <EditMetadataCard
                        key={state.render[i].secure_url}
                        slug={state.metadata[i].slug}
                        caption={state.metadata[i].caption}
                        contact={state.metadata[i].contact}
                        credit={state.metadata[i].credit}
                        background={state.metadata[i].background}
                        datePhotographed={state.metadata[i].datePhotographed}
                        filename={file.name}
                        graphicType={state.metadata[i].graphicType}
                        headline={state.metadata[i].headline}
                        imageBuffer={state.render[i].secure_url}
                        validation={state.validation[i]}
                        oneTimeUse={state.metadata[i].oneTimeUse}
                        resizeOnly={state.metadata[i].resizeOnly}
                        isBulkEditFormOpen={sidebarStatus === 'open' || sidebarStatus === 'opening'}
                        isSelected={state.bulk.selected.includes(i)}
                        selectedImages={state.bulk.selected}
                        uncredited={state.metadata[i].uncredited}
                        width={state.render[i].width}
                        height={state.render[i].height}
                        onBlur={(fieldName) => {
                          dispatch({ type: actions.VALIDATION.BLUR, payload: { fieldName, index: i } });
                        }}
                        onChange={(metadata) => {
                          dispatch({ type: actions.METADATA.EDIT, payload: { metadata, index: i } });
                          dispatch({
                            type: actions.VALIDATION.EDIT,
                            payload: {
                              fieldNames: Object.keys(metadata) as (keyof UseUploadType.Metadata.Metadata)[],
                              index: i,
                            },
                          });
                        }}
                        onDelete={() => {
                          dispatch({ type: actions.ALL.REMOVE_IMAGES, payload: { selected: [i] } });

                          // if we delete the last image, go back to the first step
                          if (state.metadata.length === 1) dispatch({ type: actions.NAVIGATION.BACK });
                        }}
                        onImageSelect={(selected) => {
                          if (selected) dispatch({ type: actions.BULK_METADATA.ADD_IMAGE, payload: { index: i } });
                          else dispatch({ type: actions.BULK_METADATA.REMOVE_IMAGE, payload: { index: i } });
                        }}
                        closeSidebar={sidebarStatus !== 'open' ? () => {} : sidebarClose}
                        onImageLoad={(img) => updateImageData({ index: i, img })}
                      />
                    ))}
                  <Group direction="column" gap="md">
                    <Typography variant="note" margin={{ horizontal: 'md' }}>
                      Required fields are marked with an asterisk{' '}
                      <Typography inline variant="note" color="lava">
                        *
                      </Typography>
                    </Typography>
                    <Typography data-testid="max-img-text" variant="note" margin={{ horizontal: 'md' }}>
                      Maximum of {constants.MAX_FILES_IN_UPLOADER[type]} images can be uploaded at a one time. (
                      {state?.files?.accepted?.length} / {constants.MAX_FILES_IN_UPLOADER[type]})
                    </Typography>
                  </Group>
                  <Button
                    data-testid="upload-more-images-button"
                    type="button"
                    icon={IconPlusCircle}
                    fullWidth
                    secondary
                    margin={{ top: 'md', bottom: 'lg' }}
                    onClick={handleBrowseFiles}
                    disabled={state.files.accepted.length === constants.MAX_FILES_IN_UPLOADER[type]}
                  >
                    Upload More Images
                  </Button>
                </Wrapper>
              </Sidebar.Page>
            </>
          );
        }}
      </Responsive>
    </Wrapper>
  );
};

export default UploadForm;
